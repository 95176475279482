export enum Urls {
  BASE = '/',
  APP = 'app',
  HOME = 'home',
  INVESTOR = 'investor',
  ENTREPRENEUR = 'entrepreneur',
  MAIN = 'main',
  GROUPS = 'groups',
  ACCOUNT = 'my-profile',
  FDI_STUDY = 'fdi-study',
  DATE_TIME = 'date-time',
  LOCATIONS = 'locations',
  INDUSTRY_AFFINITY = 'industry-affinity',
  USER_FORMAT = 'user-format',
  SEARCH_SELECTIONS_OPTIONS = 'search-selections-options',
  MEETING_TYPE = 'meeting-type',
  CHURCH = 'church',
  FACILITATOR = 'facilitator',
  GROUPS_I_LEAD = 'groups-i-lead',
  COMPANY_SIZE = 'company-size',
  INDUSTRY_PAGE = 'industry',
  AFFINITY_PAGE = 'affinity',
  GROUP_NOT_FOUND = 'not-found',

  USER = 'user',

  AUTH = 'auth',
  GET_STARTED = 'get-started',
  LOGIN = 'login',
  REGISTER = 'register',
  CONFIRM = 'confirm',
  APPLICATION_FAILED = 'application-failed',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  CONFIRM_EMAIL = 'confirm-email',
  MY_GROUPS = 'my-groups',

  ADMIN = 'admin',
  MANAGE_GROUPS = 'manage-groups',
  MANAGE_FACILITATORS = 'manage-facilitators',
  MANAGE_USERS = 'manage-users',
  MANAGE_STUDIES = 'manage-studies',
  GROUPS_REQUESTS = 'groups-requests',
  MANAGE_LOCATIONS = 'manage-locations',
  MANAGE_CHURCHES = 'manage-churches',
  MANAGE_PARTNER_ORGANIZATIONS = 'manage-partner-organizations',
  MANAGE_TAGS = 'manage-tags',
  ADD_GROUP = 'add-group',

  SERVER_ERROR = 'serverError',

  FOUNDATION = 'foundation',
  FILTER = 'filter',

  //WIZARD URLS
  WIZARD_HOME = 'app/home',
  WIZARD_INVESTOR = `app/home/filter/investor`,
  WIZARD_LOCATION = `app/home/filter/locations`,
  WIZARD_USER_FORMAT = `app/home/filter/user-format`,
  WIZARD_MEETING_TYPE = `app/home/filter/meeting-type`,
  WIZARD_SEARCH_SELECTIONS_OPTIONS = `app/home/filter/search-selections-options`,
  // WIZARD_INDUSTRY_AFFINITY = `app/home/filter/industry-affinity`,
  WIZARD_DATE_TIME = `app/home/filter/date-time`,
  WIZARD_COMPANY_SIZE = `app/home/filter/company-size`,
  WIZARD_INDUSTRY = `app/home/filter/industry`,
  WIZARD_AFFINITY = `app/home/filter/affinity`,
}
